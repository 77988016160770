<script>
import api from '@/api/index.js'
import tool from '@/tool'
import base from '@/templates/list'
import MyDialog from './dialog'
export default {
  directives: {},
  filters: {},
  components: {
    MyDialog
  },
  extends: base,
  props: {},
  data () {
    return {
      showExport: false,
      handleTableColumnWidth: 225,
      tableAction: {
        show: true,
        add: { show: false, title: '新增' },
        remove: { show: false, title: '删除' },
        edit: { show: false, title: '编辑' },
        view: { show: true, title: '详情' },
        valid: { show: false },
        updateStatus: { show: false }
      },
      showList: [
        // {
        //   copName: 'el-input',
        //   placeholder: '图片名称',
        //   key: 'name',
        //   name: '',
        //   attrs: ['placeholder']
        // }
      ],
      tableTile: [
        {
          columnLabel: '序号',
          type: 'index',
          width: '',
        },
        {
          columnLabel: '公司名称',
          prop: 'companyName',
          width: '',
        },
        {
          columnLabel: '姓名',
          prop: 'name',
          width: '',
        },
        {
          key: 'email',
          columnLabel: '邮箱',
          prop: 'email',
          width: ''
        },
        {
          key: 'phone',
          columnLabel: '电话',
          prop: 'phone',
          width: ''
        },
        {
          key: 'yourNeed',
          columnLabel: '需求描述',
          prop: 'yourNeed',
          width: ''
        },
      ]
    }
  },
  created () {
  },
  methods: {
    init () {
      this.toPage(1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    onSubmit () {
      this.loading = true
      const query = {
        page: this.formInline.page.currentPage,
        size: this.formInline.page.size,
        queryParam: {
          ...this.formInline,
          queryParam: null,
          page: null
        }
      }
      api.contactUsLite.queryPage(query)
        .then((res) => {
          if (res.data && res.data.records) {
            this.tableData = res.data.records
            this.formInline.page.total = res.data.total
          }
          this.loading = false
        })
    },
    view (id) {
      const curItem = this.tableData.find(item => item.id == id)
      console.log(curItem)
      this.$refs.myDialog.view(curItem)
    }
  }
}
</script>
